<template>
  <el-dialog
    title="绑定Vip卡"
    width="90%"
    class="bind_card_modal"
    :destroy-on-close="true"
    :append-to-body="true"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    @close="close"
  >
   <div style="color: #000;margin-top: -20px;padding-bottom: 10px;">
        卡号可联系微信13842677847购买
      </div>
    <el-form :model="form" ref="ruleForm" :rules="rules">
     
      <el-form-item label="卡号" prop="number">
        <el-input v-model="form.number" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="bindingCard('ruleForm')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      formLabelWidth: "170px",
      dialogFormVisible: false,
      form: {
        number: ""
      },
      rules: {
        number: [{ required: true, message: "请输入卡号", trigger: "blur" }]
      }
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    routePath: {
      type: String,
      default: "/"
    },
    reload: {
      type: Boolean,
      default: false
    },
    vipType: {
      type: Number,
      default: 1
    }
  },
  watch: {
    showModal: function() {
      this.dialogFormVisible = this.showModal;
    },
    vipType: function(newVal) {
      console.log("---", newVal);
      if (newVal == 1) {
        this.title = "绑定Vip卡（测评）";
      } else if (newVal == 2) {
        this.title = "绑定Vip卡（模拟志愿）";
      }
    }
  },
  mounted() {
    this.form.number = "";
    if (this.vipType == 1) {
      this.title = "绑定Vip卡（测评）";
    } else if (this.vipType == 2) {
      this.title = "绑定Vip卡（模拟志愿）";
    }
  },
  methods: {
    close() {
      this.form.number = "";
      this.$emit("closeModal", false);
    },
    bindingCard(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const _this = this;
          const reqData = Object.assign(this.form, { type: this.vipType });
          const res = await this.$axios.post("/api/user/bind_card/", reqData);
          if (res && res.data.errno == 0) {
            this.$toast("绑定成功");
            if (this.reload) {
              window.location.reload();
            }else {
              setTimeout(() => {
                _this.$router.push({ path: _this.routePath });
              }, 3000);
            }
          }
          this.close();
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.bind_card_modal {
  top: 50%;
    transform: translate(0, -50%) !important;
    z-index: 9999;
     border-radius: 10px;
     height: 325px;
  /deep/ .el-dialog {
    border-radius: 6px;
  }
  /deep/ .el-dialog__body {
    padding: 14px 20px 0 !important;
  }
  /deep/ .dialog-footer{
    padding: 0;
  }
}
</style>
