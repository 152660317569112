<template>
  <div class="page">
    <div class="info">
      <van-icon name="arrow-left" class="back" @click="goBack" />
      <div>{{ query && query.grade }}分</div>
      <div>{{ query && query.course.replaceAll(',', '/') }}</div>
      <div @click="clickPici">
        {{ piciVal && piciVal.bzType }} <i class="el-icon-caret-bottom"></i>
      </div>
    </div>
    <div class="fillBox" @click="handleClickVip">
      <img src="../../assets/image/one-key.png" alt="" />
    </div>
    <div class="mark">
      <div class="mark_row" v-if="!isShowMore">
        <div class="freeTimes" @click="jumpVip">
          <!-- 免费使用<span>{{ vipNum }}</span
          >次， -->
          <span>开通VIP无限使用</span>
        </div>
      </div>
      <div class="mark_row">
        <div class="userSet" @click="clickSet">个性化设置</div>
      </div>
      <div class="mark_row" style="margin-top:-10px">
        <div class="one-key-paragraph" @click="handleTip">
          推荐原理及说明
        </div>
      </div>
    </div>
    <van-popup
      v-model="showTip"
      closeable
      close-icon-position="top-right"
      position="bottom"
      round
      get-container="body"
      :style="{ height: '27%' }"
    >
      <div class="tip_box">
        <div class="title">推荐原理及说明:</div>
        <div class="text">
          依据考生成绩，并结合用户关注的、填报的、浏览的院校、专业、职业为画像及个性化设置要求，配合专业定位测评及院校、专业热门指数，按冲、稳、保规则录取概率从小到大排序生成个性化的志愿表。苛刻的个性化设置条件可能会导致志愿表生成数量过少的情况，建议扩大设置范围。
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="showVip"
      :close-on-click-overlay="false"
      close-icon-position="top-right"
      position="center"
      round
      get-container="body"
      :style="{ width: '90%' }"
    >
      <div class="vip_box">
        <div class="vip_text">
          <div class="van-dialog__message">
            免费使用 <span style="color: #FC4B4C">{{ vipNum }}</span
            >次,<span style="color: #FC4B4C">开通VIP无限使用</span>
          </div>
        </div>
        <div class="vip_btn">
          <van-button
            class="btn btn1"
            plain
            surround
            type="warning"
            large
            @click="close"
            >取消</van-button
          >
          <van-button
            class="btn btn2"
            plain
            surround
            @click="clickVip"
            type="primary"
            >立即开通</van-button
          >
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="piciShow"
      :close-on-click-overlay="false"
      close-icon-position="top-right"
      position="top"
      get-container="body"
      overlay-class="pici_box"
      :overlay-style="{ top: '54px' }"
      :style="{ background: '#fff', top: '54px' }"
    >
      <div class="van-row" style="padding: 16px 16px 30px;">
        <h1 class="colorgray085 f20 fw600">更改填报批次/段</h1>
        <div class="h5-default-table-block mt15">
          <div
            class="f16 colorgray9999 h5-default-table-block-item mb5"
            style="height: 54px;"
            v-for="(item, index) in piciList && piciList.list"
            :key="index"
            @click="setPici(item)"
          >
            <div
              class="colorgray085  fw600"
              style="width: 28%;"
              :class="piciVal && piciVal.bzType == item.bzType ? 'active' : ''"
            >
              {{ item.bzType }}
            </div>
            <div class="f14 text-right" style="width: 24%;">
              批次线：{{ item.score }}
            </div>
            <!-- <div style="width: 8%;">
            <i class="icon-zk van-icon van-icon-arrow van-cell__right-icon"></i>
          </div> -->
          </div>
        </div>
      </div>
    </van-popup>
    <card-bind
      :showModal="showModal"
      @closeModal="showModal = false"
      :routePath="''"
      :vipType="2"
      :reload="true"
    ></card-bind>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cardBind from '@/components/cardBind/index.vue';

export default {
  components: {
    cardBind,
  },
  data() {
    return {
      showTip: false, //
      showVip: false, //
      clearTimer: null,
      vipNum: 0,
      piciShow: false,
      piciList: null,
      piciVal: {
        bzType: '',
        course: '',
        id: 0,
        majorBzType: '',
        score: null,
      },
      query: null,
      planData: {
        categories: [],
        chooseSubject: [],
        collegeList: [],
        cost: [],
        features: [],
        jobList: [],
        majorList: [],
        majorType: [],
        maxScore: 0,
        minScore: 0,
        newCollege: [],
        newMajor: [],
        pcId: '',
        province: [],
      },
      vipList: {},
      isShowMore: false,
      showModal: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userinfo,
    }),
  },
  destroyed() {
    clearTimeout(this.clearTimer);
  },
  watch: {},
  async mounted() {
    const vipList = await this.$axios.get('/php/user.info');
    this.vipList = vipList.data.data.vipList;
    this.isShowMore = this.vipList[2];
    const { name, path, query } = this.$router.currentRoute;
    this.query = query;
    console.log(this.$router.currentRoute, 'router===zz', this.userInfo);
    await this.getPiciList();
    // if (name == 'personalization') {
      this.planData = this.$store.state.setFill;
    // }
  },
  async created() {
    console.log('===zz', this.userInfo);
  },
  methods: {
    async getPiciList() {
      let apiUrl = `/php/pici.list`;
      try {
        const res = await this.$axios.get(apiUrl);
        console.log('==res', res);
        if (res.data.ret == 0) {
          this.piciList = res.data.data;
          this.piciVal = res.data.data.list[0];
          this.planData.pcId=this.piciVal.id
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    //clickPici
    clickPici() {
      this.piciShow = true;
    },
    setPici(item) {
      this.piciVal = item;
      this.piciShow = false;
      this.planData.pcId=this.piciVal.id
      // this.$toast('加速更新中，敬请期待~')
      //   if (true) return;
      // this.$router.push({
      //   path: '/accounts/fill',
      //   query: {
      //     bzType: item.bzType,
      //     course: item.course,
      //     id: item.id,
      //     majorBzType: item.majorBzType,
      //     score: item.score,
      //   },
      // });
    },
    //
    jumpVip() {
      // this.$router.push({ path: '/accounts/vip' });
      this.showModal = true;
    },
    //开通VIP
    async handleClickVip() {
      // if (!this.vipNum) {
      //   this.showVip = true;
      //   return
      // }
      if (!this.isShowMore) {
        // this.showMore()
        return false;
      }
      const res = await this.$axios.post('/php/plan.oneKey', this.planData);
      if (res.data.ret == 0) {
        this.$message({
          message: '填报成功',
          type: 'success',
        });
        //     跳转到志愿表详情
        this.$router.push(
          '/tzy/details?id=' + encodeURIComponent(res.data.data)
        );
      }
    },
    close() {
      this.showVip = false;
    },
    clickVip() {
      this.showVip = false;
      this.clearTimer = setTimeout(() => {
        this.$router.push({ path: '/accounts/fill' });
      }, 500);
    },
    //推荐原理及说明
    handleTip() {
      this.showTip = true;
    },
    //返回
    goBack() {
      this.$router.go(-1);
    },
    //个性化设置
    clickSet() {
      this.$router.push({
        path: '/accounts/one-fill',
        query: {
          bzType: this.piciVal.bzType,
          course: this.piciVal.course,
          id: this.piciVal.id,
          majorBzType: this.piciVal.majorBzType,
          score: this.piciVal.score,
          grade: this.query.grade,
          courseBack: this.query.course,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mark {
  position: fixed;
  left: 0;
  bottom: 50%;
  width: 100%;
  margin-top: 10vw;
  transform: translate(0, 50vw);
}
.mark_row {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  .userSet {
    padding: 10px 40px;
    border-radius: 30px;
    color: #fff;
    position: relative;
    // width: 150px;
    // height: 40px;
    border: 1px solid #fff;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #fff;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
  }
  .one-key-paragraph {
    margin: 0 auto;
    text-align: center;
    height: 14px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    line-height: 14px;
  }
  .freeTimes {
    background-color: rgba($color: #fff, $alpha: 0.8);
    border-radius: 30px;
    padding: 10px 30px;
    span {
      color: #b38086;
    }
  }
}

.info {
  display: flex;
  position: absolute;
  top: 15px;
  left: 15px;
  align-items: center;
  div {
    color: #fff;
    font-size: 16px;
    margin-right: 5px;
  }
  .back {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-right: 8px;
  }
}
.page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(79,175,252);
  // background: linear-gradient(45deg,#419fe5,#5acbd3);
  background: #fff linear-gradient(146deg, #019ce3, #3ad4d0);
}
.fillBox {
  position: absolute;
  left: 50%;
  top: 20%;
  margin-left: -90px;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  // border: solid 1px #fff;
  box-shadow: 0 0 30px #eee;
  // box-sizing: border-box;
  // background: linear-gradient(-45deg, #59c8d1, #46b1da);
  // text-align: center;
  // line-height: 180px;
  // color: #fff;
  // font-size: 24px;
  // letter-spacing: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100% !important;
    padding: 0 !important;
  }
}
.tip_box {
  border-radius: 8px 8px 0 0;
  padding: 16px 28px;
  // padding-bottom: 60px;
  .title {
    margin: 16px 0 8px 0;
    margin-bottom: 10px;
    width: 112px;
    height: 20px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #212121;
    line-height: 20px;
  }
  .text {
    display: inline-block;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #212121;
    line-height: 20px;
  }
}
.vip_box {
  padding: 20px 14px 50px;
  .vip_text {
    font-size: 16px;
    padding-bottom: 10px;
  }
  .vip_btn {
    position: relative;
    height: auto;
    display: flex;
    justify-content: center;
    /deep/ .btn {
      border-radius: 100px;
      height: 40px !important;
      line-height: 1.2;
      width: 40%;
      margin: 0 2%;
    }
    /deep/ .btn1 {
      background: #fff;
      color: #212121;
      border: 1px solid #9b9b9b;
    }
    /deep/ .btn2 {
      background: -webkit-linear-gradient(left, #ff6034, #ee0a24);
      background: linear-gradient(90deg, #ff6034, #ee0a24);
      border: none !important;
      color: #fff;
    }
  }
}
.colorgray085 {
  color: rgba(0, 0, 0, 0.85);
}

.fw600 {
  font-weight: 600 !important;
}
.f20 {
  font-size: 20px !important;
}
.active {
  color: #910000;
}
.h5-default-table-block .h5-default-table-block-item {
  padding-left: 14px;
  background: #fff;
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  justify-content: space-between;
}
/deep/ .van-overlay.pici_box {
}
</style>
